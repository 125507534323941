exports.components = {
  "component---src-templates-billing-periods-tsx": () => import("./../../../src/templates/BillingPeriods.tsx" /* webpackChunkName: "component---src-templates-billing-periods-tsx" */),
  "component---src-templates-confirmation-banner-index-tsx": () => import("./../../../src/templates/ConfirmationBannerIndex.tsx" /* webpackChunkName: "component---src-templates-confirmation-banner-index-tsx" */),
  "component---src-templates-domains-cross-sell-cards-tsx": () => import("./../../../src/templates/DomainsCrossSellCards.tsx" /* webpackChunkName: "component---src-templates-domains-cross-sell-cards-tsx" */),
  "component---src-templates-domains-standard-cards-tsx": () => import("./../../../src/templates/DomainsStandardCards.tsx" /* webpackChunkName: "component---src-templates-domains-standard-cards-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/Index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-new-platfrom-tower-index-tsx": () => import("./../../../src/templates/NewPlatfromTowerIndex.tsx" /* webpackChunkName: "component---src-templates-new-platfrom-tower-index-tsx" */),
  "component---src-templates-standard-cards-tsx": () => import("./../../../src/templates/StandardCards.tsx" /* webpackChunkName: "component---src-templates-standard-cards-tsx" */),
  "component---src-templates-test-tsx": () => import("./../../../src/templates/Test.tsx" /* webpackChunkName: "component---src-templates-test-tsx" */)
}

